/*
import React, { useRef, useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import '../styles/sliderHome.css';
import '../styles/navMobile.css'; // Estilos para dispositivos móviles

import img1 from '../images/slider/comprascolectivasinteligentes.mp4';
import img2 from '../images/slider/PromoGasolina.mp4';
import img3 from '../images/slider/PromoMascotas.mp4';

function SliderHome() {
    const carouselRef = useRef(null);
    const [videosPlaying, setVideosPlaying] = useState(false);

    const videos = [
      { url: img1, link: '/register' },
      { url: img2, link: '/gasolina' },
      { url: img3, link: '/products/4' },
    ];
  
    const handleVideoEnded = () => {
      // Cambia al siguiente video cuando un video haya terminado
      carouselRef.current.next();
    };
  
    useEffect(() => {
      // Reproducir los videos una vez que la página esté lista
      setVideosPlaying(true);
    }, []);
  
    return (
      <div className='slider_home'>
        <Carousel ref={carouselRef} controls={false} width="100%">
          {videos.map((video, index) => (
            <Carousel.Item key={index} style={{ width: '100vw' }}>
              <div style={{ width: '100vw' }}>
                <Link to={video.link}>
                  <ReactPlayer
                    url={video.url}
                    playing={videosPlaying} // Controlar manualmente la reproducción
                    loop
                    muted
                    controls={false}
                    onEnded={handleVideoEnded}
                    width="100%"
                    height="60vh"
                    style={{ objectFit: 'fill' }}
                  />
                </Link>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
  
  export default SliderHome;
*/import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../styles/sliderHome.css';
import img1 from '../images/slider/PromoComprasColectivasTuColonia.mp4';
import img2 from '../images/slider/PromoGasolinaTuColonia.mp4';
import img3 from '../images/slider/PromoMascotasTuColonia.mp4';
import flechaizq from '../images/flecha_izq.svg';
import flechader from '../images/flecha_der.svg';
import { ButtonCommon } from '../components/buttons';

import { ButtonCircle } from './buttons';



const videos = [
  { url: img1, link: 'https://www.tucolonia.co/register', title:'Compras colectivas inteligentes', subtitle:'Pides individualmente, nosotros unimos los pedidos y obtienes ahorros como si compraras al por mayor'},
  { url: img2, link: '/promociones/autos', title:'¡Si eres parte de tu Colonia viajar es más barato!', subtitle:'Inscribete fácilmente y obtén un descuento exclusivo Tu Colonia'},
  { url: img3, link: '/promociones/mascotas', title:'Promo para tu mascota hasta el 30 de Octubre', subtitle:'Alimento para tu mascota, Arena, medicamentos OTC, lociones y más!'},
  // Agrega más videos según sea necesario
];

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 40vh;
    margin: 100px auto;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

const TitlesContainer = styled.div`
position: absolute; /* Posicionamiento absoluto */
top: 300px; /* Posiciona en la parte superior */
left: 0; /* Posiciona en la izquierda */
width: 100%; /* Ancho completo del contenedor */
display: flex; /* Permite flexbox para la alineación */
flex-direction: column; /* Alineación en columna */
align-items: center; /* Alineación horizontal al centro */
justify-content: center; /* Alineación vertical al centro */
padding: 20px; /* Adición de espacio interno */
background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
color: white; /* Color de texto blanco */
z-index: 1; /* Posición en el eje z por encima del video */
/* Responsive adjustments */
  @media (max-width: 768px) {
    top: 200px; /* Adjust top position for smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
    font-size: 16px; /* Reduce font size for titles and subtitles */
  }
  @media (max-width: 480px) {
    top: 150px; /* Further adjust top position for extra small screens */
    font-size: 14px; /* Further reduce font size */
  }
`;

const TitleSlider = styled.div`
margin: 0; /* Elimina márgenes predeterminados */
  font-size: 34px; /* Tamaño de fuente para el título */
  font-weight: bold; /* Texto en negrita */
  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 28px; /* Reduce font size for smaller screens */
  }
  @media (max-width: 480px) {
    font-size: 24px; /* Further reduce font size */
  }
`;

const SubtitleSlider = styled.div`
  smargin: 10px 0; /* Márgenes entre párrafo y título */
  font-size: 20px; /* Tamaño de fuente para el subtítulo */
  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 16px; /* Reduce font size for smaller screens */
  }
  @media (max-width: 480px) {
    font-size: 14px; /* Further reduce font size */
  }
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Controls = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1;
  @media (max-width: 768px) {
    top: 30%;
  }
  @media (max-width: 480px) {
    top: 30%;
  }
`;

const Button = styled.button`
  margin: 0 10px;
`;

const LinkWrapper = styled(Link)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const SliderHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const playerRef = useRef(null);

  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const prevVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  };

  return (
    <CarouselContainer>
      {videos.map((video, index, title, subtitle) => (
        <VideoContainer key={index} active={index === currentIndex}>
          <TitlesContainer>
            <div>
            <TitleSlider>{video.title}</TitleSlider>
            <SubtitleSlider><p>{video.subtitle}</p></SubtitleSlider>
            <a href={video.link} typeForm={'fill'} style={{ textDecoration: 'none', color: 'inherit',backgroundColor: '#87B338', padding: '8px 10px', boxShadow: '5px 6px 0px 0px #000000', borderWidth: '1px', borderRadius: '15px', fontWeight: '500', minWidth: '150px' }} target="_blank" rel="noopener noreferrer" onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(0.95)';
                            e.currentTarget.style.backgroundColor = '#FEFCFB';
                            e.currentTarget.style.color = '#87B338';
                            e.currentTarget.style.border = '#87B338 solid 1px';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.backgroundColor = '#87B338';
                            e.currentTarget.style.color = '#fff';
                            e.currentTarget.style.border = 'none';
                        }}>¡Suscribete ahora!</a>
          </div>
          </TitlesContainer>
          <VideoElement
            src={video.url}
            autoPlay
            muted
            loop
          />
        </VideoContainer>
      ))}
      <Controls>
        <ButtonCircle onClick={prevVideo} pathImg={flechaizq} type="fill" />
        <ButtonCircle onClick={nextVideo} pathImg={flechader} type="fill" />
      </Controls>
    </CarouselContainer>
  );
};

export default SliderHome;