import styled from 'styled-components';
import { Colors } from '../styles/global';
import { TextTitleCard, TextParagraphNormalSmall, TextParagraphNormalSmaller } from './texts';
import { ButtonCommon, ButtonSuscription } from '../components/buttons';
import { FaWhatsapp } from 'react-icons/fa';
import ProgressBarf from '../components/ProgressBarf';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import Toast from './Toast';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'

const ContainerCardPromo = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
    background-color: ${Colors.tertiary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 300px;
    height: auto;
    @media (max-width: 768px) {
        width: 100%;

    }
`

const ImageContainer = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
`;

const DiscountContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DBFE9E;
    border-radius: 15px;
    padding: 10px 5px;
    margin-top: 10px;
    margin-bottom: 20px;
`

const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    padding: 20px;
`;

const OverlayImage = styled.img`
    width: 130px;
    border-radius: 10%;
    position: relative;
    object-fit: fill;
`;

const PromoTitle = styled(TextParagraphNormalSmaller)`
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    color: ${Colors.white};
    font-weight: 600;
`;

// Estilos omitidos para brevedad

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Popup = styled.div`
    width: 450px;
    height: auto;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    @media (max-width: 768px) {
        width: 90%;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
    width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CardPromo = ({ promotion }) => {



    const [showPopup, setShowPopup] = useState(false); // Estado para controlar si el pop-up está abierto
    const [showPopupLogin, setShowPopupLogin] = useState(false); // Estado para controlar si el pop-up está abierto
    const [questions, setQuestions] = useState([]); // Estado para almacenar las preguntas de la API
    const [supplierImage, setSupplierImage] = useState('');
    //Toast
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const [formData, setFormData] = useState({});

    const handleChange = (event, questionId, questionLabel) => {
        setFormData({
            ...formData,
            [questionId]: {
                label: questionLabel,
                info: event.target.value
            }
        });
    };

    const showToast = (message, type) => {
        setToastMessage(message);
        setToastType(type);
        setToastVisible(true);

        // Ocultar el toast después de 3 segundos
        setTimeout(() => {
            setToastVisible(false);
        }, 3000);
    };

    const handleSubmit = async (event) => {

        event.preventDefault()

        // Se obtienen los datos del usuario del local para tomar el id
        const userInfoAllString = localStorage.getItem("userInfoAll")

        const userInfoAll = JSON.parse(userInfoAllString); // Convierte la cadena JSON a un objeto JavaScript
        //console.log(userInfoAll.id)

        // Enviar datos para guardar la suscripcion
        const suscripcion = {
            user_id: userInfoAll.id,
            promo_id: promotion.id,
        }

        console.log(JSON.stringify(suscripcion))
        try {
            const response = await fetch('https://thor-fast-api.herokuapp.com/subscription/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Añade aquí cualquier header adicional que necesites, como autorización
                },
                body: JSON.stringify(suscripcion)
            });

            if (!response.ok) {
                throw new Error('Failed to submit data');
            }

            // Si todo está bien, muestra un toast con feedback

            showToast('Suscrito Correctamente', 'success');
        } catch (error) {
            console.error('Error submitting data:', error);
            // Si hay un error, muestra un toast con el mensaje de error
            showToast('Error submitting data Subs', 'error');
        }


        // Obtener los datos del formulario
        const formDataArray = Object.values(formData);

        // Aquí puedes enviar los datos al servidor utilizando fetch

        //anexar campo de idUser a cada elemento dentro formDataArray
        formDataArray.forEach(item => {
            // anexar el campo idUser al {}
            item.user_id = userInfoAll.id
        })

        console.log(JSON.stringify(formDataArray))

        // Aquí puedes enviar los datos al servidor utilizando fetch
        try {
            const response = await fetch('https://thor-fast-api.herokuapp.com/user/addinfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Añade aquí cualquier header adicional que necesites, como autorización
                },
                body: JSON.stringify(formDataArray)
            });

            if (!response.ok) {
                throw new Error('Failed to submit data');
            }

            // Si todo está bien, muestra un toast con feedback
            setShowPopup(false)

        } catch (error) {
            console.error('Error submitting data:', error);
            // Si hay un error, muestra un toast con el mensaje de error
            showToast('Error submitting data', 'error');
        }


    };



    const renderPopup = () => {
        return (
            <PopupOverlay>
                <Popup>
                    <Form onSubmit={handleSubmit}>
                        {questions.map(question => (
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '10px', alignItems: 'start' }} key={question.id}>
                                <label style={{ fontWeight: 'bold', color: '#635994' }}>{question.label}</label>
                                <Input
                                    type="text"
                                    placeholder={question.question}
                                    value={formData[question.id]?.info || ''}
                                    onChange={(event) => handleChange(event, question.id, question.label)}
                                />
                            </div>
                        ))}
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '10px', gap: '10px' }}>
                            <ButtonCommon onClick={() => setShowPopup(false)} text={'Cerrar'} typeForm={'out'} />
                            <ButtonCommon text={'Enviar'} typeForm={'fill'} type="submit" />
                        </div>
                    </Form>
                </Popup>
            </PopupOverlay>
        );
    };

    const renderPopupLogin = () => {
        return (
            <PopupOverlay>
                <Popup>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '10px', alignItems: 'center' }}>
                        <TextTitleCard style={{ textAlign: 'center', color: '#635994', fontWeight: 'bold' }}>Para poder suscribirte, inicia sesión o registrate</TextTitleCard>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '10px', gap: '10px' }}>
                            <ButtonCommon onClick={() => setShowPopupLogin(false)} text={'Cerrar'} typeForm={'out'} />
                            <Link to='/login'><ButtonCommon text={'Iniciar Sesión'} typeForm={'fill'} /></Link>
                        </div>
                    </div>
                </Popup>
            </PopupOverlay>
        );
    };

    const handleSubscribeClick = async () => {
        const userInfoAllString = localStorage.getItem("userInfoAll");

        // Verificar si hay un usuario en el localStorage
        if (!userInfoAllString) {
            // Mostrar el popup para iniciar sesión o registrarse
            setShowPopupLogin(true);
            return; // Detener el flujo aquí para evitar continuar con la suscripción
        } else if (userInfoAllString)

            try {
                const response = await fetch(`https://thor-fast-api.herokuapp.com/promotion/questions/${promotion.id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch questions');
                }
                const data = await response.json();
                setQuestions(data);
                setShowPopup(true);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
    };


    const calculateTimeLeft = () => {
        const difference = +new Date(promotion.end_promotion_dttm) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                d: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                m: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [promotion.end_promotion_dttm]);

    // Obtener la imagen del proveedor
    useEffect(() => {
        const fetchSupplier = async () => {
            try {
                const response = await fetch(`https://thor-fast-api.herokuapp.com/supplier/${promotion.supplier_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch supplier');
                }
                const supplierData = await response.json();
                setSupplierImage(supplierData.image);
            } catch (error) {
                console.error('Error fetching supplier:', error);
            }
        };

        fetchSupplier();
    }, [promotion.supplier_id]);


    const timerComponents = [];

    Object.keys(timeLeft).forEach(interval => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    const [subscriptions, setSubscriptions] = useState([]); // Para almacenar los datos de las suscripciones
    const [stateSubscriptionPromotion, setStateSubscriptionPromotion] = useState(false);

    useEffect(() => {

        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get('https://thor-fast-api.herokuapp.com/subscription/');
                setSubscriptions(response.data);

                let isUserSubscribed = false;
                {
                    subscriptions.map((subscription) => (
                        <div key={subscription.id}>
                            <p>User ID: {subscription.user_id}</p>
                            <p>Promo ID: {subscription.promo_id}</p>
                            <p>ID: {subscription.id}</p>
                        </div>
                    ))
                }

                for (const subscriptions of response.data) {
                    if (subscriptions.user_id === nameUserId && subscriptions.promo_id === promotion.id) {
                        isUserSubscribed = true;
                        break;
                    }
                }
                setStateSubscriptionPromotion(isUserSubscribed);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSubscriptions();
    }, []);


    const [supplier, setSupplier] = useState([]); // Para almacenar los datos de las suscripciones


    useEffect(() => {

        const fetchSupplier = async () => {
            try {
                const response = await axios.get(`https://thor-fast-api.herokuapp.com/supplier/${promotion.supplier_id}`);
                setSupplier(response.data);



            } catch (error) {
                console.error(error);
            }
        };

        fetchSupplier();
    }, []);

    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const [nameUserId, setUserNameId] = useState('')

    //

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails


    //

    const isAuthenticated = userInfo !== null;

    const userInfoAllString = localStorage.getItem("userInfoAll");

    useEffect(() => {
        // Obtiene el objeto userInfoAll del localStorage

        if (userInfoAllString) {
            // Parsea la cadena JSON para obtener un objeto JavaScript
            const userInfoAll = JSON.parse(userInfoAllString);
            // Obtén el nombre de usuario del objeto userInfoAll y guárdalo en el estado
            setUserNameId(userInfoAll.id);
            console.log(nameUserId)
        }
    }, [userInfoAllString]);
    //console.log(subscriptions.user_id, "subscriptions.user_id", nameUserId, "nameUserId", subscriptions.promo_id, "subscriptions.promo_id", promotion.id, "promotion.promo_id")
    let isUserSubscribed = false;

    // Función para verificar si el código promocional es una URL
    const isUrl = (codigo) => {
        try {
            const url = new URL(codigo);
            return url.protocol === 'https:';
        } catch (e) {
            return false;
        }
    };

    // Componente para mostrar el código promocional
    const PromoCodeDisplay = () => (
        <div>
            <div className="fw-bold text-dark">Promo Code</div>
            <span className="text-dark me-2">{promotion.codigo_promocional}</span>
            <span
                className="badge text-bg-primary rounded-pill"
                onClick={() => navigator.clipboard.writeText(promotion.codigo_promocional)}
            >
                <i className="fas fa-copy p-1"></i>
            </span>
            {/* Campo adicional para mostrar la página donde se puede redimir el código promocional */}
            <div className="mt-2">
                <div className="fw-bold text-dark">Redimir en:</div>

                <a href={supplier.web_page} target="_blank" rel="noopener noreferrer" style={{ color: 'green', textDecoration: 'underline' }}>
                    Ir a la página
                </a>

            </div>
        </div>
    );

    // Componente para mostrar la promoción con enlace de WhatsApp
    const PromoLinkDisplay = () => (
        <div>
            <div className="fw-bold text-dark">Escríbenos y obtén tu promoción</div>
            <a href={promotion.codigo_promocional} target="_blank" rel="noopener noreferrer" className="text-success d-flex align-items-center mt-2">
                <FaWhatsapp size={20} className="me-2" />
                WhatsApp
            </a>
        </div>
    );



    return (
        <ContainerCardPromo>

            <div style={{ width: '100%', height: '40%', position: 'relative' }}>
                <ImageContainer src={promotion.product_images} alt="" />
                <OverlayContainer>
                    <div style={{ position: 'relative' }}>
                        <OverlayImage src="/images/promociones/timeFigure.svg" alt="" />
                        <PromoTitle>{timerComponents.length ? timerComponents : <span>Se agotó!</span>}</PromoTitle>
                    </div>

                </OverlayContainer>
            </div>


            <div style={{ padding: "10px 20px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <TextTitleCard>{promotion.name}</TextTitleCard>

                    {/* Aquí agregamos la imagen del proveedor */}
                    {supplierImage && <img style={{ width: "35%", height: "30px", borderRadius: "10%", objectFit: "fill" }} src={supplierImage} alt="Logo Proveedor" />}
                </div>

                <TextParagraphNormalSmall style={{ marginTop: "10px" }}>{promotion.description}</TextParagraphNormalSmall>

                <DiscountContainer>
                    <TextParagraphNormalSmall style={{ fontWeight: "600" }}>{promotion.msg_promo}</TextParagraphNormalSmall>
                </DiscountContainer>
                {/* Botón "Suscribirse" */}
                {/*<ButtonSuscription text={'Únete aquí'} variant='primary' userId={localStorage.id} onClick={handleSubscribeClick} promoId={promotion.id} />*/}
                <ProgressBarf percentage={promotion.current_quantity_users} />

                <>
                    {subscriptions.some((subscription) => {
                        isUserSubscribed = subscription.user_id === nameUserId && subscription.promo_id === promotion.id;
                        return isUserSubscribed;
                    }) ? (
                        <>
                            <ol className="list-group list-group-numbered">
                                <li className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className="d-flex align-items-center mb-3">

                                            <span className="text-white fw-bold bg-success rounded-pill px-3 py-2">¡Ya estás suscrito.!</span>
                                        </div>

                                        <div>
                                            {isUrl(promotion.codigo_promocional) ? <PromoLinkDisplay /> : <PromoCodeDisplay />}
                                        </div>
                                    </div>

                                </li>
                            </ol>

                        </>
                    ) : (
                        <>
                            <ButtonCommon text={'Suscribirse'} typeForm={'fill'} variant='primary' onClick={handleSubscribeClick} />
                        </>
                    )}
                </>
                {/*  {stateSubscriptionPromotion === false  ? (
                <>
                    <ButtonCommon text={'Suscribirse'} typeForm={'fill'} variant='primary' onClick={handleSubscribeClick} />
                </>
                ) : (
                 <>
                    <TextParagraphNormalSmall style={{ fontWeight: "600" }}>Si esta</TextParagraphNormalSmall>
                </>
          )}*/}

                {/* Renderizar el pop-up si showPopup es true */}
                {showPopup && renderPopup()}
                {showPopupLogin && renderPopupLogin()}
                {/* Renderizar el toast si está visible */}
                {toastVisible && <Toast message={toastMessage} type={toastType} visible={toastVisible} />}
            </div>



        </ContainerCardPromo>
    )
}

export default CardPromo