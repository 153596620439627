import styled from 'styled-components';
import "../styles/navbar.css";
import "../styles/email.css";
import React, { useRef, useEffect, useState } from "react";
import { Colors } from '../styles/global';
import { ButtonCommon } from '../components/buttons';
import { Link, useParams,  useLocation,  useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Styled Component */
const MainContainer   = styled.div`
padding: 20px;
`;


const ContainerMain = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const TextTitle = styled.h2`
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 auto;
    font-size: 24px; /* Aumenta el tamaño de la fuente */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`

const TitleSection = styled.h2`
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    colot: white;
    @media (max-width: 868px) {
    font-size: 1.5rem;
  }
`

const GradientContainer = styled.div`
  background-image: linear-gradient(to right, #f09377, #f9d866); /* Ejemplo de gradiente */
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  width: 100%; /* Ancho del 100% */
  display: flex; /* Contenedor flexbox para centrar el contenido */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
`;


const TextParagraphNormal = styled.p`
    color: #666666;
    font-size: 18px;
    `

const ButtonCommonFill = styled.button`
    background-color: #87B338;
    color: #FEFCFB;
    padding: 8px 10px;
    min-width: 150px;
    /* sombra del boton*/
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    border-radius: 15px;
    transition: transform 0.3s; /* Agrega una transición a la propiedad transform */

    //hover
    &:hover{
        background-color: #FEFCFB;
        color: #87B338;
        border: #87B338 solid 1px;
        transform: scale(0.95); /* Reduce el tamaño del botón al 95% en hover */
    }
    `

const ContainerContentText = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
    `;

const ContainerSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    @media (max-width: 768px) {
      flex-direction: column !important;
    }
  `;


const ContainerImage = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `;

  const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  flex: 1; /* Added for flexbox layout, takes up remaining space */
`;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  flex: 1; /* Added for flexbox layout, takes up remaining space */
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 26px;
`;

const ListItemIcon = styled.span`
  margin-right: 10px;
`;


const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ContainerSectionsComoFunciona = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
`

const Email=()=>{
    const userInfoAllString = localStorage.getItem("userInfoAll")

    const userInfoAll = JSON.parse(userInfoAllString);
    return(
            
        <ContainerMain>
            <div className="work-with-us-section">
            <TitleSection style={{ marginTop: '10px', color: '#EFEFEF' }}>¡TRABAJA CON NOSOTROS!</TitleSection>
    
            <div className="work-with-us-content">
            <h2 className="work-with-us-title">Sé un Gestor Local de Tu Colonia</h2>
            <TextTitle style={{ color: '#644209', }}>¡ Gratis, Rápido y Fácil ! </TextTitle>
            <p className="work-with-us-description">
                Obtienes ingresos promoviendo las Compras Colectivas Inteligentes.
            </p>
            </div>
        </div>  
           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent: 'start'}}>
               
            {/* Seccion 02*/}
            <MainContainer>
            <TitleSection style={{ marginBottom: '50px', textAlign: 'center' }}>Ventajas de ser facilitador</TitleSection>
      <ListContainer>
        <ListItem>
          <ListItemIcon style={{ color: '#ffa705', fontSize:'30px' }}> <i className="fas fa-star"></i></ListItemIcon>
          No tienes que invertir, no tienes que comprar
productos o servicios, no tienes que hacer
cobros.
        </ListItem>
        <ListItem>
          <ListItemIcon style={{ color: '#ffa705', fontSize:'30px' }}> <i className="fas fa-star"></i></ListItemIcon>
          Solo impulsas en tu comunidad las
inscripciones a Tu Colonia y motivas para que
se hagan pedidos en el sistema.
        </ListItem>
        <ListItem>
          <ListItemIcon style={{ color: '#ffa705', fontSize:'30px' }}> <i className="fas fa-star"></i></ListItemIcon>
          Tu ganas por las compras efectivas que ellos hagan en la plataforma
        </ListItem>
        <ListItem>
          <ListItemIcon style={{ color: '#ffa705', fontSize:'30px' }}> <i className="fas fa-star"></i></ListItemIcon>
          El sistema lleva las cuentas de tus ganancias automáticamente y pagamos tu cuenta.
        </ListItem>
        {/* ... rest of the list items */}
      </ListContainer>
    </MainContainer>

   
      
    

           </div>
           <div>
           <ContainerSection>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Sé un Facilitador de Tu Colonia</TitleSection>
                        <Title>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>Obtienes ingresos promoviendo las Compras Colectivas Inteligentes.</TextParagraphNormal>
                        </Title>
                        <Title style={{ marginBottom: '30px' }}>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>No tienes que invertir, no tienes que comprar productos o servicios, no tienes que hacer cobros.</TextParagraphNormal>
                        </Title>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/facilitador.svg' />
                    </ContainerImage>
            </ContainerSection>
            </div>

           <GradientContainer>
        <TitleSection>Ingresa y entérate de las promociones que tenemos para ti</TitleSection>
      </GradientContainer>          
        </ContainerMain>
    )
}
export default Email

/*


*/